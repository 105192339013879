<template>
  <div class="home">
    <Navbar></Navbar>
    <CampSearch :camps="camps"></CampSearch>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import CampSearch from '../components/ParentDashboard/CampsSearch.vue'
import moment from 'moment'
import CampFinder from '@/api-services/finder.service.js';

export default {
  name: 'Home',
  components: {
    'Navbar': Navbar,
    'CampSearch': CampSearch,
    'Footer': Footer
  },
  data () {
    return {
      camps: []
    }
  },
  mounted() {
    CampFinder.findCamps()
    .then((response) => {
      let formatedCamps = this.FormatDates(response.data)
      this.camps = formatedCamps
    })
  },
  methods: {
    FormatDates(camps) {
      let formatedCamps = camps
      for(let i = 0; i<camps.length; i++) {
        formatedCamps[i].startDate = moment(camps[i].startDate).format('YYYY-MM-DD')
        formatedCamps[i].endDate = moment(camps[i].endDate).format('YYYY-MM-DD')
      }
      return formatedCamps
    }
  },
}
</script>
